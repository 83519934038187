<template>
  <DataTable :value="rows" class="p-datatable-small" tableStyle="min-width: 50rem">
    <Column field="EnvioId" header="N° Envio" style="font-size: 0.75rem">
      <template #body="{ data }">
                <span class="text-xs underline text-blue-600 cursor-pointer" v-if="$can('pharmasan.inventario.operaciones-stock.recepcion-tecnica.access')" @click="OnClickEnvio(data.EnvioId)">
                  {{ data.EnvioId }}
                </span>
                <span v-else class="text-xs">
                  {{ data.EnvioId }}
                </span>
      </template>
    </Column>
    <Column field="Guia" header="Guia" style="font-size: 0.75rem"/>
    <Column field="DocDate" header="Fecha" style="font-size: 0.75rem"/>
    <Column header="Bodega Destino" style="font-size: 0.75rem">
      <template #body="{ data }">
        <div class="flex flex-col">
          <span>{{ data.WhsName  }}</span>
          <span>{{ data.WhsCode  }}</span>
        </div>
      </template>
    </Column>
  </DataTable>
</template>
<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
defineProps({
    rows: {
      type: Array,
      required: true
    }
})
const router = useRouter()
const OnClickEnvio = (EnvioId) => {
  router.push({ name: 'pharmasan.inventario.operaciones-stock.recepcion-tecnica.enivio', params: { EnvioId } })
}
</script>

<style scoped>

</style>
